<template>
  <div class="w-full overflow-auto">
    <div class="max-w-screen-lg">
      <div>
        <div v-if="hasFinancialReportAccess">
          <h2 class="text-lg font-medium text-gray-900">{{ $tk('Reports.Invoice', true) }}</h2>
          <div class="flex flex-wrap">
            <report-link
              class="w-full md:w-1/3 mt-2"
              v-for="(report, index) in invoiceReports"
              :key="index"
              :report="report"
            />
          </div>
        </div>

        <div v-if="transactionReports.length > 0">
          <h2 class="mt-8 text-lg font-medium text-gray-900">{{ $tk('Reports.BalanceControlAndTransactions', true) }}
          </h2>
          <div class="flex flex-wrap">
            <report-link
              class="w-full md:w-1/3 mt-2"
              v-for="(report, index) in filter(transactionReports, r => r.available)"
              :key="index"
              :report="report"
            />
          </div>
        </div>
      </div>

      <div class="">
        <div v-if="targitReports && Object.keys(targitReports).length > 0">
          <div
            v-for="(category, key) in targitReports"
            :key="key"
          >
            <div v-if="getTargitReports(category).length > 0">
              <h2 class="text-lg w-full mt-8 font-medium text-gray-900">{{ key }}</h2>
              <div class="w-full flex flex-wrap">
                <a
                  href="#"
                  @click="open(report.id)"
                  class="flex w-full md:w-1/3 mt-2"
                  v-for="(report, index) in getTargitReports(category)"
                  :key="index"
                >
                  <div class="flex mr-3 py-2 px-4 bg-white hover:shadow-md">
                    <div class="flex flex-wrap">
                      <div class="w-12 m-auto">
                        <img
                          class="object-scale-down"
                          :src="targitReportImageUrl(report.id)"
                        />
                      </div>
                    </div>
                    <div class="flex flex-wrap ">
                      <div class="ml-2 text-base w-full">{{ report.title }}</div>
                      <div class="ml-2 text-xs w-full">{{ report.description }}</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="baseReports.length > 0 && hasBaseReportAccess">
        <h2 class="mt-8 text-lg font-medium text-gray-900">{{ $tk('Reports.BaseData', true) }}</h2>
        <div class="flex flex-wrap">
          <report-link
            class="w-full md:w-1/3 mt-2"
            v-for="(report, index) in baseReports"
            :key="index"
            :report="report"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http"
import ReportLink from "../components/ReportLink"
import TokenService from "@/services/TokenService"
import { mapGetters } from 'vuex'
import { filter } from 'lodash'
import { getConfig } from "@/config"

const config = getConfig();

export default {
  name: 'Reports',
  data() {
    return {
      targitReports: []
    }
  },
  computed: {
    ...mapGetters([
      "customer",
      "location",
      "user"
    ]),
    invoiceReports() {
      return [
        { "path": "invoices/customer", text: this.$tk('Reports.InvoicesFromSR', true) },
        { "path": "purchases", text: this.customer.invoiceTypeIOId > 0 ? this.$tk('Reports.ReverseInvoice', true) : this.$tk('Reports.InvoiceBasisFromSR', true) }
      ]
    },
    hasBaseReportAccess() {
      return this.user.allowStandardReports
    },
    hasFinancialReportAccess() {
      return this.user.allowFinancialReports
    },
    transactionReports() {
      return [
        { "path": "commisions", text: this.$tk('Reports.BalanceControlCommission', true), available: this.commissionAvailable },
        { "path": "pooling", text: this.$tk('Reports.BalanceControlPooling', true), available: this.poolingAvailable }
        // { "path": "transactions", text: this.$tk('Reports.Transactions', true), available: true},
      ]
    },
    baseReports() {
      return this.location.isPooling ? [
        { "path": "users", text: this.$tk('Reports.Users', true), icon: 'user' },
        { "path": "locations", text: this.$tk('Reports.Locations', true) },
      ] : []
    },
    poolingAvailable() {
      if (this.user.reportLevel === 1) {
        return this.customer.isPooling
      } else {
        return this.location.isPooling
      }
    },
    commissionAvailable() {
      if (this.user.reportLevel === 1) {
        return this.customer.isCommission
      } else {
        return this.location.isCommission
      }
    }

  },
  components: {
    ReportLink,
  },
  methods: {
    filter,
    async open(id) {
      let report = await http.post("report", { id })
      window.open(report.url)
    },
    targitReportImageUrl(id) {
      return config.apiUrl + 'ReportIcon?id=' + encodeURIComponent(id) + '&authtoken=' + encodeURIComponent(TokenService.getToken())
    },
    getTargitReports(reports) {
      // console.debug(reports)
      return reports.filter(r => (r => r.isFinancial === false || r.isFinancial === null) || (r.isFinancial && this.hasFinancialReportAccess))
    },
  },
  async created() {
    this.targitReports = await http.get("Reports")
  }
}
</script>
